<template>
    <div class="page-sip-trunking-tools container">
        <div class="row">
            <div class="col-12">
                <div class="header-info">
                    <h1 class="name">Tools</h1>
                </div>

                <app-tabs v-model="tab" :tabs="tabs"></app-tabs>
            </div>
        </div>

        <router-view @open-sidebar="openSidebar" @close-sidebar="closeSidebar"></router-view>
    </div>
</template>

<script>
import appTabs from '@/components/app-tabs'

export default {
    components: {
        appTabs,
    },

    data() {
        return {
            tabs: [
                {
                    id: 'common-tasks',
                    name: 'sip-trunking-tools-common-tasks',
                    title: 'Common Tasks',
                },
                // {
                //     id: 'advanced',
                //     name: 'sip-trunking-tools-advanced',
                //     title: 'Advanced',
                // },
            ],
        }
    },

    methods: {
        openSidebar() {
            this.$emit('open-sidebar')
        },

        closeSidebar() {
            this.$emit('close-sidebar')
        },
    },

    computed: {
        tab: {
            get() {
                const tab = this.tabs.find(tab => this.$route.name == tab.name || this.$route.name == tab.alias)

                if (!tab) {
                    this.tab = { name: 'sip-trunking-tools-common-tasks', replace: true };
                }

                return tab;
            },

            set(tab) {
                this.$router.push({
                    name: tab.name,
                    replace: true
                });
            }
        },
    },

    beforeRouteUpdate(to, from, next) {
        this.closeSidebar()
        next()
    },

    beforeRouteLeave(to, from, next) {
        this.closeSidebar()
        next()
    },
}
</script>

<style lang="scss">
.page-sip-trunking-tools {
    margin-top: 24px;
    padding-bottom: 80px;

    .app-tabs {
        margin-bottom: 34px;
    }

    .header-info {
        display: flex;
        justify-content: space-between;
        width: fit-content;
        margin-bottom: 40px;

        .name {
            font-size: 56px;
            line-height: 62px;
            font-weight: bold;
        }
    }
}

@media (max-width: $tablet-size) {
    .page-sip-trunking-tools {
        .header-info {
            width: 100%;
            margin-bottom: 48px;

            .name {
                font-size: 48px;
                line-height: 56px;
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .page-sip-trunking-tools {
        .header-info {
            flex-direction: column;
            margin-bottom: 32px;

            .name {
                font-size: 32px;
                line-height: 40px;
            }
        }

        .app-tabs {
            margin-bottom: 16px;
        }
    }
}
</style>